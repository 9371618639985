<template>
  <v-sheet class="customer" style="">
    <v-row>
      <v-col md="12" class="">
        <!-- <pre>{{ searchParam }}</pre> -->
        <v-row>
          <v-col md="6" class="my-auto py-0">
            <h1 class="custom-header-blue-text m-0">Customers</h1>
           
            <!-- <SelectInput
              hide-details
              style="max-width: 150px"
              hideTopMargin
              :loading="pageLoading"
              :disabled="pageLoading"
              class="ml-2"
              item-text="text"
              dense
              item-value="value"
              :items.sync="dropdownFilter"
              v-model="filterType"
              @change="filterTypeChange"
            ></SelectInput>
            <div style="max-width: 600px" class="ml-2">
              <DateRangePicker
                hide-details
                :disabled="pageLoading"
                clearable
                hideTopMargin
                v-model="filterDateRange"
                :loading="pageLoading"
                @clear="(filterDateRange = []), getCustomers()"
                id="item-sale-duration"
                placeholder="Date Range"
              ></DateRangePicker> -->
            <!-- </div> -->
          </v-col>

          <v-col md="6" class="text-right py-0">
            

            <!-- <v-btn
              :disabled="pageLoading"
              class="mr-2 white--text"
              depressed
              @click="whatsappPromotional = true"
              color="green darken-4"
              tile
            >
              Whatsapp Promotional
              <v-icon class="ml-1" small>mdi-whatsapp</v-icon>
            </v-btn> -->
            <v-btn
              :disabled="pageLoading"
              class="mr-2 white--text"
              depressed
              v-on:click="allCollectedDialog = true"
              color="deep-orange darken-4"
              tile
            >
              Redeem Voucher
            </v-btn>

            <v-btn
              :disabled="pageLoading"
              color="blue darken-4"
              depressed
              tile
              class="mr-2 white--text"
              :to="{ name: 'customer-create', query: { t: new Date().getTime() } }"
            >
              New Customer
            </v-btn>
            <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      color="blue darken-4"
                      depressed
                      tile
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      class="white--text mr-4"
                    >
                      <v-icon>edit_note</v-icon>
                    </v-btn>
                  </template>
                  <span>Columns arrangement and visibility</span>
                </v-tooltip>
              </template>
              <v-list nav dense>
              
                <v-list-item
                  draggable
                  @dragstart="dragstart(index)"
                  @dragover="dragover(index)"
                  @dragend="dragEnd"
                  v-for="(item, index) in defaultColDefs"
                  :key="item.field + '_' + index"
                  class="flex-column column-arrangement-list-item cursor-move"
                >
                  <div class="d-flex flex-row">
                    <template v-if="!item.checkbox || Number(item.checkbox) == 0">
                      <v-checkbox
                        :ripple="false"
                        hide-details
                        color="#0d47a1"
                        v-model="defaultColShow"
                        v-bind:value="item.field"
                        :disabled="Number(item.fixed) || pageLoading"
                        v-on:change="updateVisible()"
                        class="m-0"
                      ></v-checkbox>

                      <v-list-item-title>{{ item.headerName }}</v-list-item-title>
                      <v-icon>drag_indicator</v-icon>
                    </template>
                  </div>
                  <v-divider class="mt-1 mb-0" style="width: 100%"></v-divider>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          
        </v-row>
      </v-col>
      <v-col md="12" class="mt-0 py-0">
        <SearchCriteria
          v-if="listingSearch"
          :search="listingSearch"
          search-string="Customer #, Full Name, Email, Phone No. and Address"
          v-on:close-search="resetSearch"
        ></SearchCriteria>
        <!-- style="height: calc(100vh - 200px);"  -->
        <v-simple-table fixed-header class="bt-table table_height">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="heading in customThead"
                  :key="heading.title"
                  style="background-color: #f5f5f5"
                >
                  <div>{{ heading.headerName }}</div>
                </th>
              </tr>
            </thead>
            <tbody v-if="!pageLoading">
              <!-- v-if="employeeList.length" -->
              <!-- {{ employeeList }} -->
              <template v-if="customers.length">
                <tr
                  v-for="(row, bkey) in customers"
                  :key="bkey"
                  :class="bkey % 2 === 0 && 'blue lighten-5'"
                  class="employee-listing-tr"
                  link
                  v-on:click="routeToDetail(row.id)"
                >
                  <td v-for="(th, index) in customThead" :key="'key_' + index">
                    <div v-if="th.field === 'id'"></div>

                    <div v-else-if="th.field === 'actions'">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            fab
                            dark
                            x-small
                            class="mx-2"
                            color="green"
                            v-bind="attrs"
                            v-on="on"
                            v-on:click.stop.prevent="routeToUpdate(row.id)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            fab
                            dark
                            x-small
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                            v-on:click.stop.prevent="deleteConfirm(row)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="th.field === 'full_name'" class="cursor-pointer">
                      <ValueTemplate :title="'full_name'" :value="row.full_name"></ValueTemplate>
                    </div>
                    <div v-else-if="th.field === 'customer'" class="cursor-pointer">
                      <v-chip class="ma-2" color="teal" text-color="white" label>
                        <ValueTemplate :title="'barcode'" :value="row.barcode"></ValueTemplate>
                      </v-chip>
                    </div>

                    <div v-else-if="th.field === 'joining_date'" class="cursor-pointer">
                      <ValueTemplate
                        :title="'joining date'"
                        :value="formatedateTime(row.joining_date)"
                      ></ValueTemplate>
                    </div>

                    <div v-else-if="th.field === 'type'" class="cursor-pointer">
                      <v-chip
                        v-if="row.type != 'gold'"
                        small
                        outlined
                        label
                        class="text-capitalize"
                        color="blue"
                        >{{ row.type }}
                      </v-chip>
                      <v-chip
                        v-else
                        small
                        outlined
                        label
                        class="text-capitalize"
                        :color="row.days == 'Completed' ? 'success' : '#DAA520'"
                      >
                        {{ row.type }}</v-chip
                      >

                      <div class="mt-2">
                        <template v-if="row.type != 'gold'">
                          <v-chip
                            small
                            outlined
                            label
                            class="text-capitalize"
                            :color="row.days == 'Completed' ? 'success' : 'red'"
                          >
                            Expiry {{ row.days }} Days</v-chip
                          >
                        </template>
                      </div>
                    </div>

                    <div v-else-if="th.field === 'phone'" class="cursor-pointer">
                      <ValueTemplate
                        :title="' phone number'"
                        :value="row.phone_number"
                      ></ValueTemplate>
                    </div>
                    <div v-else-if="th.field === 'email'" class="cursor-pointer">
                      <v-chip color="#0D47A1" outlined>
                        <ValueTemplate :title="'Email'" :value="row.email"></ValueTemplate>
                      </v-chip>
                    </div>

                    <div v-else-if="th.field === 'job_type'" class="cursor-pointer">
                      <v-chip color="#0D47A1" outlined>
                        <ValueTemplate
                          style="text-transform: uppercase"
                          :title="' job type'"
                          :value="row.role_name"
                        ></ValueTemplate>
                      </v-chip>
                    </div>
                    <div v-else-if="th.field === 'dob'" class="cursor-pointer">
                      <ValueTemplate :title="'DOB'" :value="row.date_of_birth"> </ValueTemplate>
                    </div>
                    <div v-else-if="th.field === 'address'" class="cursor-pointer">
                      <ValueTemplate :title="'address'" :value="row.address"> </ValueTemplate>
                    </div>
                    <div v-else-if="th.field === 'country'" class="cursor-pointer">
                      <ValueTemplate
                        :title="'no after probation period'"
                        :value="row.country"
                      ></ValueTemplate>
                    </div>
                    <div v-else-if="th.field === 'reservation'" class="cursor-pointer">
                      
                  <template v-if="row?.booking[0]">
                    <div>  Date & Time :&nbsp;&nbsp;{{formatedateTime(row?.booking[0]?.booking_date)}}  <span>{{(row?.booking[0]?.booking_time)}}</span></div>
                   <div>  Source  : &nbsp;&nbsp;<span class="text-capitalize">{{row?.booking[0]?.source}}</span></div>
                   <div>  Event  : &nbsp;&nbsp;{{row?.booking[0]?.type}}</div>
                 
                
                      <v-chip
                        v-if="row?.booking[0]?.status == 1"
                        small
                        outlined
                        label
                        color="purple"
                        >New</v-chip
                      >
                      <v-chip
                        v-else-if="row?.booking[0]?.status == 2"
                        small
                        outlined
                        label
                        color="green"
                        >Approved</v-chip
                      >
                      <v-chip
                        v-else-if="row?.booking[0]?.status == 3"
                        small
                        outlined
                        label
                        color="cyan"
                        >Pending</v-chip
                      >
                      <v-chip
                        v-else-if="row?.booking[0]?.status == 4"
                        small
                        outlined
                        label
                        color="orange"
                        >Canceled</v-chip
                      >
                      <v-chip
                        v-else-if="row?.booking[0]?.status == 5"
                        small
                        outlined
                        label
                        color="red"
                        >Rejected</v-chip
                      >
                      <v-chip
                        v-else-if="row?.booking[0]?.status == 6"
                        small
                        outlined
                        label
                        color="black"
                        >No Show</v-chip
                      >
                      
                    </template>
                    <template v-else>
                      -
                    </template>
                    
               
                 
                    </div>
                    <div
                      v-else-if="th.field === 'created_at' && th.status"
                      link
                      v-on:click="routeToDetailSupplier(row)"
                    >
                      <p class="my-auto py-2">
                        <v-chip class="py-0" small outlined color="primary" label>
                          <ValueTemplate
                            :value="row?.added_by?.display_name"
                            title="Admin"
                          ></ValueTemplate>
                          <!-- {{ row[getColValue(th.title)]?.value[0].createdBy_admin }} -->
                        </v-chip>
                      </p>
                      <p>
                        <v-chip small label style="font-weight: 600">
                          <!-- <ValueTemplate :value="row.formatDate" title="Created Date"></ValueTemplate> -->
                          {{ formatedateTimes(row.added_at) }}
                          <!-- {{ row[getColValue(th.title)]?.value[0].created_date }} -->
                        </v-chip>
                      </p>
                    </div>
                    <div
                      v-else-if="th.field === 'updated_at' && th.status"
                      link
                      v-on:click="routeToDetailSupplier(row)"
                    >
                      <template v-if="row.updated_at">
                        <!-- {{ formatedateTimes(row.updated_at) }} -->
                        <!-- <p class="my-auto py-2">
                      <v-chip class="py-0" small outlined color="primary" label>
                        <ValueTemplate :value="row[getColValue(th.title)]?.value[0].createdBy_admin"
                          title="Created by admin"></ValueTemplate>
                      </v-chip>
                    </p> -->
                        <p>
                          <v-chip small label style="font-weight: 600">
                            {{ formatedateTimes(row.updated_at) }}
                            <!-- {{ row[getColValue(th.title)]?.value[0].created_date }} -->
                          </v-chip>
                        </p>
                      </template>
                      <template v-else>
                        <p style=" font-weight: 600">no modified</p>
                      </template>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="9">
                    <p class="m-0 text-center">
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mr-4"
                      />
                      Uhh... There are no customers at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <tr v-for="idr in 9" :key="`_$skeleton_$_locader_${idr}`">
                <td v-for="idk in 9" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
        <v-layout v-if="customers.length" class="light-border-top mt-4">
          <v-flex md6>
            <label class="btx-label p-4 pb-0"
              >Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label
            >
          </v-flex>
          <v-flex md6>
            <v-pagination
              color="blue darken-4"
              v-model="currentPage"
              :length="pageLimit"
              :total-visible="7"
            ></v-pagination>
          </v-flex>
        </v-layout>
        <OrderTemplate
          v-model="order"
          :customer-id="customerId"
          :dialog="orderDialog"
          v-on:close="orderDialog = false"
          v-on:save="saveOrder()"
          v-on:collect:voucher="getAvailableVouchers"
          v-on:claim:voucher="getCollectedVouchers"
        ></OrderTemplate>
        <CollectTemplate
          :dialog="collectDialog"
          :order-id="cOrderId"
          :order-code="cOrderCode"
          :vouchers="cVouchers"
          v-on:close="vCollected()"
          v-on:collected="vCollected()"
        ></CollectTemplate>
        <RedeemTemplate
          :dialog="redeemDialog"
          :order-id="rOrderId"
          :order-code="rOrderCode"
          :vouchers="rVouchers"
          v-on:close="vRedeemed()"
          v-on:redeemed="vRedeemed()"
        ></RedeemTemplate>
        <DeleteTemplate
          type="customer"
          :delete-text="deleteText"
          :delete-dialog="deleteDialog"
          :delete-endpoint="deleteEndpoint"
          v-on:close="deleteDialog = false"
          v-on:delete:success="getCustomers()"
        ></DeleteTemplate>
        <SearchTemplate
          :dialog="allCollectedDialog"
          v-on:close="allCollectedDialog = false"
        ></SearchTemplate>
        <!-- <Dialog dense :dialog="whatsappPromotional" v-if="whatsappPromotional">
          <template v-slot:title> Whatsapp Promotional </template>
          <template v-slot:body>
            <div class="mx-6" fluid>
              <v-row class="mx-6">
                <v-col md="2">
                  <label for="item-file-upload" class="btx-label mt-2 required">Template</label>
                </v-col>
                <v-col md="8">
                  <SelectInput
                    hide-details
                    hideTopMargin
                    :loading="pageLoading"
                    :disabled="pageLoading"
                    item-text="text"
                    dense
                    item-value="value"
                    :items.sync="templateList"
                    v-model="whatsapp.template"
                  ></SelectInput>
                </v-col>
              </v-row>
              <v-row class="mx-6">
                <v-col md="2">
                  <label for="item-file-upload" class="btx-label mt-2 required">File</label>
                </v-col>
                <v-col md="8">
                  <v-file-input
                    v-model="whatsapp.files"
                    ref="fileInput"
                    hide-details
                    id="item-file-upload"
                    label="Upload Images"
                    outlined
                    dense
                  ></v-file-input>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
              @click="whatsappPromotional = false"
            >
              Send
            </v-btn>
            <v-btn :disabled="pageLoading" depressed tile v-on:click="whatsappPromotional = false">
              Cancel
            </v-btn>
          </template>
        </Dialog> -->
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
// import Chip from "@/view/components/Chip";
// import Dialog from "@/view/components/Dialog";
import SearchCriteria from "@/view/components/SearchCriteria";
import { GET_CUST, GET_TEMPLATE_LIST } from "@/core/lib/customer.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import OrderTemplate from "@/view/components/OrderTemplate";
import CollectTemplate from "@/view/components/CollectVoucherTemplate";
import RedeemTemplate from "@/view/components/RedeemVoucherTemplate";
import ValueTemplate from "@/view/components/ValueTemplate";
import SearchTemplate from "@/view/components/SearchAllVouchers";
import { filter, map } from "lodash";
import moment from "moment-timezone";
import { POST } from "@/core/services/store/request.module";
import { SearchEventBus } from "@/core/lib/search.lib";

import {
  SET_SEARCH_TITLE,
  SET_SEARCH_MODEL,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
// import SelectInput from "@/view/components/SelectInput.vue";
// import DateRangePicker from "@/view/components/DateRangePicker";

export default {
  name: "customer-listing",
  title: "Listing Customer",
  data() {
    return {
      dropdownFilter: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "This Week",
          value: "this_week",
        },
        {
          text: "This Month",
          value: "this_month",
        },
      ],
      templateList: [{ text: "First", value: "first" }],
      template: "first",
      whatsapp: {
        files: [],
        template: "first",
      },
      filterType: "all",
      searchEnabled: false,
      searchParam: null,
      filterDateRange: null,
      defaultColDefs: [],
      customThead: [],
      speedDial: [],
      whatsappPromotional: false,
      customers: [],
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      customerId: null,
      orderDialog: false,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },
  watch: {
    currentPage() {
      this.getCustomers();
    },
    listingSearch() {
      this.getCustomers();
    },
    filterDateRange() {
      if (this.filterDateRange && this.filterDateRange.length > 1) {
        this.filterType = "all";
        this.getCustomers();
      }
    },
  },
  methods: {
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.defaultColDefs[this.dragStartIndex];
      console.log(dragStartElement,'dragStartElement')
      this.defaultColDefs.splice(this.dragStartIndex, 1);
      this.defaultColDefs.splice(this.dragOverIndex, 0, dragStartElement);

      this.$nextTick(() => {
        this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then((data) => {
            this.customThead = data;
            // console.log(data)
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.getCustomers();
          });
      });
    },
    updateVisible() {
      const _this = this;

      for (let index = 0; index < _this.defaultColDefs.length; index++) {
        const element = _this.defaultColDefs[index];
        _this.defaultColDefs[index].visible = 0;
        if (_this.defaultColShow.includes(element.field)) {
          _this.defaultColDefs[index].visible = 1;
        }
      }
      // console.log(this.defaultColDefs);

      _this.$nextTick(() => {
        _this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then((data) => {
            this.customThead = data;
            // console.log(data)
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.getCustomers();
          });
      });
    },
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YYYY hh:mm A");
    },
    formatedateTime(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    getAllCollectedVouchers() {},
    // changeDatePicker() {
    //   if (this.filterDateRange[0] && this.filterDateRange[1]) {
    //     this.getCustomers()
    //   }
    // },
    filterTypeChange() {
      this.filterDateRange = null;
      // this.getCustomers();
    },
    saveOrder() {
      this.customerId = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    addOrder({ id }) {
      this.customerId = id;
      this.orderDialog = true;
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.saveOrder();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.saveOrder();
    },
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.customerId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "customer-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "customer-detail",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ barcode, id }) {
      this.deleteText = barcode;
      this.deleteEndpoint = `customer/${id}`;
      this.deleteDialog = true;
    },
    async getCustomers(search = null) {
      try {
        let form = {
          current_page: this.currentPage,
          search: search,
          filter_type: this.filterType,
        };
        if (this.filterDateRange) {
          const dateRange = {
            start_date: this.filterDateRange[0],
            end_date: this.filterDateRange[1],
          };
          form = { ...form, ...dateRange };
        }
        this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows, theads } = await GET_CUST(
          form
        );
        this.customers = rows;
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
        let thead = theads;
        this.defaultColDefs = thead;
        this.customThead = [];
        for (let index = 0; index < thead.length; index++) {
          let element = thead[index];
          if (element.visible == 1) {
            this.customThead.push(element);
          }
        }

        let t_out = filter(thead, (row) => Number(row.visible) == 1);
        this.defaultColShow = map(t_out, "field");

        console.log(this.customThead);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {
          this.$store.dispatch(SET_SEARCH_MODEL, null);
        });
    },
    async getTemplateListing() {
      try {
        this.pageLoading = true;
        console.log("getTemplateListing");
        const data = await GET_TEMPLATE_LIST();
        console.log("data", data);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    let _this = this;
    SearchEventBus.$on("start-search", (argument) => {
      _this.searchEnabled = false;
      _this.searchParam = argument;
      if (_this.searchParam) {
        _this.searchEnabled = true;
      }
      this.getCustomers(argument);
    });
    SearchEventBus.$emit("search-template", true);
    this.getCustomers();
    // await this.getTemplateListing();
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Customers");
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
  components: {
    // Chip,
    DeleteTemplate,
    OrderTemplate,
    CollectTemplate,
    RedeemTemplate,
    ValueTemplate,
    SearchTemplate,
    SearchCriteria,
    // SelectInput,
    // DateRangePicker,
    // Dialog,
  },
  computed: {
    ...mapGetters(["listingSearch"]),
  },
};
</script>

<style>
.customer-table .v-data-table__wrapper {
  height: calc(100vh - 205px) !important;
}
.customer-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 99;
}

.customer-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}
</style>
